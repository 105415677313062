import React, { Component } from "react"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import { Parallax, Background } from "react-parallax"

class HomePageJourneyBlock extends Component {
    render() {
        var headerStyle = {
            textTransform: 'uppercase',
            letterSpacing: '0.2em',
            fontWeight: 200,
            fontStyle: 'normal',
            marginTop: '50px',
        }

        var headerCaptionStyle = {
            fontStyle: 'italic',
            color: 'rgb(153,153,153)',
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px', 
            fontSize: '15px',  
            lineHeight: '.5em',          
        }

        var titleStyle = {
            fontSize: '18px',
            letterSpacing: '0.2em',
            lineHeight: 1.1,
            textTransform: 'uppercase',
            fontWeight: 300,
            fontStyle: 'normal',
        }

        var captionStyle = {
            color: 'rgb(153,153,153)',
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',  
            lineHeight: 1.8,
            fontSize: '14px',
            marginBottom: '50px',
        }

        var iconStyle = {
            fontSize: '50px',
            marginTop: '1em',
            marginBottom: '1em',
        }

        return (
            <Parallax
                bgImage={require('../images/mac.jpg')}
                // bgImage={this.props.bgImage}
                bgImageAlt="mac"
                strength={500}
            >
                <MDBContainer style={{
                    color: '#fff',
                    textAlign: 'center',
                    paddingBottom: '50px',
                    paddingTop: '50px',
                }}>
                    <MDBRow>
                        <MDBCol>
                            <h2 style={headerStyle}>How it works</h2>
                            <p style={headerCaptionStyle}>Getting started is simple, just reach out for a consultation</p>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <i className="fas fa-search" style={iconStyle}></i>
                            <h4 style={titleStyle}>1. Request a Quote</h4>
                            <p style={captionStyle}>We'll do a complete assessment of your vehicle</p>
                        </MDBCol>
                        <MDBCol>
                            <i className="far fa-edit" style={iconStyle}></i>
                            <h4 style={titleStyle}>2. Get an Estimate</h4>
                            <p style={captionStyle}>Get a fixed and fair price estimate for your job</p>
                        </MDBCol>
                        <MDBCol>
                            <i className="far fa-calendar-alt" style={iconStyle}></i>
                            <h4 style={titleStyle}>3. Book an Appointment</h4>
                            <p style={captionStyle}>We'll work with your schedule to find a time that works</p>
                        </MDBCol>
                        <MDBCol>
                            <i className="fas fa-hand-sparkles" style={iconStyle}></i>
                            <h4 style={titleStyle}>4. Get Shiny!</h4>
                            <p style={captionStyle}>We'll complete the work, giving you status updates along the way</p>
                        </MDBCol>                              
                    </MDBRow>
                </MDBContainer>      
            </Parallax>
        )
    }
}

export default HomePageJourneyBlock