import React, { Component } from "react"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./CallsToAction.css"


    const CallsToAction = () => {    
        const data = useStaticQuery(graphql`
        query {
          washImage: file(relativePath: { eq: "cta-pc.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          coatingsImage: file(relativePath: { eq: "cta-modesta.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }    
          ppfImage: file(relativePath: { eq: "cta-ppf.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }                
        }
      `) 

        return (
            <MDBContainer style={{paddingTop: '40px', paddingBottom: '50px'}}>
            <MDBRow>
              <MDBCol style={{marginTop: '10px'}} className="d-flex justify-content-center">
                <div style={{width: '350px'}}>
                    <div className="flat-imagebox">
                        <div className="box-wrapper">
                        <div className="box-header">
                            <h4 className="box-title">
                            <i>Paint Correction</i>
                            </h4>
                        </div>
                        <div className="box-image">
                            <Img fluid={data.washImage.childImageSharp.fluid} />
                        </div>
                        <div className="box-content">
                            <div className="box-desc">
                                The process of sanding, compounding, and machine polishing paint to remove defects, enhance gloss, and restore clarity to your vehicle’s finish.
                            <div className="box-button">
                                <Link to="/paintrefinement">Learn More</Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>              
                </div>
              </MDBCol>
              <MDBCol style={{marginTop: '10px'}} className="d-flex justify-content-center">
                <div style={{width: '350px'}}>
                    <div className="flat-imagebox">
                        <div className="box-wrapper">
                        <div className="box-header">
                            <h4 className="box-title">
                            <i>Modesta Coatings</i>
                            </h4>
                        </div>
                        <div className="box-image">
                            <Img fluid={data.coatingsImage.childImageSharp.fluid} />
                        </div>
                        <div className="box-content">
                            <div className="box-desc">
                            High quality ceramic coatings designed to protect your vehicle’s finish, while also simplifying the maintenance needed to keep your vehicle looking great.
                            <div className="box-button">
                                <Link to="/ceramiccoatings">Learn More</Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>              
                </div>
              </MDBCol>
              <MDBCol style={{marginTop: '10px'}} className="d-flex justify-content-center">
                <div style={{width: '350px'}}>
                    <div className="flat-imagebox">
                        <div className="box-wrapper">
                        <div className="box-header">
                            <h4 className="box-title">
                            <i>SunTek Protective Film</i>
                            </h4>
                        </div>
                        <div className="box-image">
                            <Img fluid={data.ppfImage.childImageSharp.fluid} />
                        </div>
                        <div className="box-content">
                            <div className="box-desc">
                            Custom installed, optically clear, film applied to specific areas of your vehicle to help prevent impact damage and scratches.
                            <div className="box-button">
                                <Link to="/paintprotectionfilm">Learn More</Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>              
                </div>
              </MDBCol>                     
            </MDBRow>        
          </MDBContainer>            
        )
    }

export default CallsToAction