import React, { Component } from "react"
import { Link } from "gatsby"
import { 
  MDBRow, 
  MDBCol, 
  MDBContainer, 
  MDBBtn, 
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBView,
  MDBMask
} from "mdbreact"

import Layout from "../components/TLCLayout"
import SEO from "../components/seo"
import HomePageJourneyBlock from "../components/HomePageJourneyBlock"
import CallsToAction from "../components/CallsToAction"

import Img from "gatsby-image"

// const IndexPage = () => (
class IndexPage extends Component {
  render(){

    const galleryImageControls = []

    for (let i=0; i < 5; i++){
      galleryImageControls.push(
        <MDBCol style={{backgroundColor: '#000', padding: 0}}>
            <Link to="/gallery">
              <MDBView hover zoom>
                  <Img fluid={this.props.data.galleryImages.edges[i].node.frontmatter.image.childImageSharp.fluid} />                                        
                  <MDBMask className="flex-center" overlay="black-strong">
                      <p className="white-text" style={{fontSize: '5em'}}><i class="fas fa-search-plus"></i></p>
                  </MDBMask>                    
              </MDBView>
            </Link>
        </MDBCol>      
      )
    }
    
    return(
      <Layout homepage heroTitle="Passion Drives Perfection" heroSubtitle="The premier automotive protection and restyling studio in the DMV area " heroImageUrl="./images/hero-dark-2.jpg">
        <SEO title="Home" />

        {/* Services CTAs */}
        <CallsToAction />

        {/* Partner Images */}
        <MDBContainer fluid style={{
          backgroundColor: '#f6f6f6',
        }}>
          <MDBContainer>
            <MDBRow style={{
              paddingTop: '20px',
              paddingBottom: '20px',
            }}>
              <MDBCol md="2">
                <h4 style={{
                  color: '#333',
                  textTransform: 'uppercase',
                  letterSpacing: '0.2em',
                  fontSize: '20px',
                  padding: '1rem',
                  margin: 0,
                }}>
                  The Highest Quality
                </h4>
              </MDBCol>
              <MDBCol middle md="2">
                <img src="images/logo-modesta.png" width="100%" />
              </MDBCol>
              <MDBCol middle md="2">
                <img src="images/logo-clearguard.png" width="100%" /> 
              </MDBCol>
              <MDBCol middle md="2">
                <img src="images/logo-suntek.png" width="100%" />
              </MDBCol>
              <MDBCol middle md="2">
                <img src="images/logo-spectra.png" width="100%" />
              </MDBCol>  
              <MDBCol middle md="2">
                <img src="images/logo-exoshield.png" width="100%" />
              </MDBCol>                            
            </MDBRow>
          </MDBContainer>
        </MDBContainer>

        {/* Customer Journey Section */}
        {/* <HomePageJourneyBlock bgImage={this.props.data.journeyImage.childImageSharp.fluid} /> */}
        <HomePageJourneyBlock />

        {/*  About and Contact */}
        <MDBContainer>
          <MDBRow style={{paddingBottom: '50px'}}>
            <MDBCol sm="6" md="4">
              <h3 style={{marginTop: '50px', marginBottom: '40px'}}>About TLC</h3>
              <img src="images/team.jpg" width="100%" />
              <p><span class="dropcap">W</span>e were founded in 2006 on the principal that every 
                vehicle deserves to be cared for and protected. We strive
                for perfection in all things related to the aesthetics and 
                care of a vehicle. The owner and employees of TLC Auto 
                Detail have always held ourselves to the highest level of 
                quality in everything we do. We are a full service auto 
                detailing and restoration shop that specializes in the 
                restoration and continued care of luxury, performance, and 
                high end exotic vehicles.</p>
            </MDBCol>
            <MDBCol sm="6" md="4">
              <h3 style={{marginTop: '50px', marginBottom: '40px'}}>At a Glance</h3>
              <div className="flat-iconList">
                <ul className="iconlist">
                  <li className="circle"><i className="fa fa-check"></i>2017 IDA Detail Shop of the Year</li>
                  <li className="circle"><i className="fa fa-check"></i>In business for over 12 years</li>
                  <li className="circle"><i className="fa fa-check"></i>Over 50 years of combined experience</li>
                  <li className="circle"><i className="fa fa-check"></i>Modesta Coating Certified Installer</li>
                  <li className="circle"><i className="fa fa-check"></i>SunTek Film Certified Installer</li>
                  <li className="circle"><i className="fa fa-check"></i>ExoShield Windshield Film Certified Installer</li>
                  <li className="circle"><i className="fa fa-check"></i>Clear Guard Nano Certified Installer</li>
                  <li className="circle"><i className="fa fa-check"></i>Spectra Photosync Certified Installer</li>            
                </ul>
              </div>
            </MDBCol>
            <MDBCol>
              <MDBContainer>
                  <h3 style={{marginTop: '50px', marginBottom: '40px'}}>Make an Appointment</h3>
                  <MDBCard>
                      <MDBCardBody>
                        <form name="Website Contact" data-netlify="true" method="POST">
                          <input type="hidden" name="form-name" value="Website Contact" />
                          <div className="grey-text">
                              <MDBInput name="Name" label="Your name" group type="text" validate error="wrong"
                              success="right" />
                              <MDBInput name="Email" label="Your email" group type="email" validate error="wrong"
                              success="right" />
                              <MDBInput name="Subject" label="Subject" group type="text" validate error="wrong" success="right" />
                              <MDBInput name="Message" type="textarea" rows="2" label="Services Requested" />
                          </div>
                          <div className="text-center">
                              <MDBBtn color="secondary" type="submit">
                              Send
                              </MDBBtn>
                          </div>
                        </form>
                      </MDBCardBody>
                  </MDBCard> 
              </MDBContainer>  
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* Gallery Preview */}
        <MDBContainer fluid>
          <MDBRow>
            {galleryImageControls}
          </MDBRow>
        </MDBContainer>

        
      </Layout>
    )
  }
}  
// )

export default IndexPage

export const pageQuery = graphql`
  query {
    # journeyImage: file(relativePath: { eq: "mac.jpg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 250) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
    galleryImages: allMarkdownRemark(
        limit: 5,
        sort: {fields: frontmatter___date, order: ASC}
    ){
        edges {
            node {
                frontmatter {
                    title
                    image {
                        relativePath
                        childImageSharp {
                            fluid(maxWidth: 300) {
                              ...GatsbyImageSharpFluid
                            }
                        }
                    }                                   
                }
            }
        }
        pageInfo {
            perPage
            pageCount
            itemCount
            hasPreviousPage
            hasNextPage
            currentPage
        }
    }    
  }
`